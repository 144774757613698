<script setup lang="ts">
import type { PropType } from "vue";
import { useDisplay } from "vuetify";
import type { ISlide } from "~/lib/slide";

const { items } = defineProps({
    items: {
        type: Array as PropType<ISlide[]>,
        default: () => []
    },
    text: {
        type: String,
        default: "Take look at gallery"
    },
    decoratedPhrase: {
        type: String,
        default: "gallery"
    },
    watermarkText: {
        type: String,
        default: "gallery"
    }
});

const { sm, md, lg, xlAndUp, smAndUp } = useDisplay();

const height = computed(() => {
    if (xlAndUp.value) return "18.75rem";
    if (lg.value) return "15rem";
    if (md.value) return "13rem";
    if (sm.value) return "10rem";
    return "7.25rem";
});

const slideGroup = ref(0);
const selectedImage = ref<string | number>(0);
const isCarouselOpen = ref(false);

function nextSlide() {
    slideGroup.value++;
    slideGroup.value = slideGroup.value == items.length - 1 ? (slideGroup.value = 0) : slideGroup.value;
}

function prevSlide() {
    slideGroup.value--;
    slideGroup.value = slideGroup.value == -1 ? (slideGroup.value = items.length - 1) : slideGroup.value;
}
</script>

<template>
    <SectionWrapper id="Gallery" full-width :watermark-text="watermarkText" watermark-on-top class-name="pb-0">
        <h2 class="text-center mb-4 mb-sm-5 mb-md-10 mb-lg-15">
            <DecoratedTitle :text="text" :decorated-phrase="decoratedPhrase" />
        </h2>

        <VSlideGroup
            v-if="items.length"
            v-model="slideGroup"
            class="mb-0 position-relative"
            mobile-breakpoint="sm"
            tabindex="0"
            :max="10"
            :show-arrows="smAndUp"
        >
            <VSlideGroupItem v-for="image in items" :key="image.id" :value="image.id">
                <GalleryImageCard
                    :src="image.src"
                    :alt="image.alt"
                    :height="height"
                    :fit="image.fit"
                    aspect-ratio="1"
                    @click="
                        isCarouselOpen = !isCarouselOpen;
                        selectedImage = image.id;
                    "
                />
            </VSlideGroupItem>
            <template #prev>
                <VBtn variant="flat" color="transparent" class="text-white" icon="$prev" @click="prevSlide"></VBtn>
            </template>
            <template #next>
                <VBtn variant="flat" color="transparent" class="text-white" icon="$next" @click="nextSlide"></VBtn>
            </template>
        </VSlideGroup>

        <GalleryCarousel v-if="items?.length" v-model="isCarouselOpen" :items="items" :selected="selectedImage" />
    </SectionWrapper>
</template>

<style lang="scss" scoped>
:deep(.v-slide-group__prev),
:deep(.v-slide-group__next) {
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    z-index: 10;
}
:deep(.v-slide-group__next) {
    right: 0;
}
:deep(.v-slide-group__content) {
    justify-content: center;
}
</style>
